@import url(//hello.myfonts.net/count/3aac62);
/**
 * @license
  * MyFonts Webfont Build ID 3845218, 2019-12-08T13:06:33-0500
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: HelveticaNowDisplay-Regular by Monotype
 * URL: https://www.myfonts.com/fonts/mti/helvetica-now/display-regular/
 * 
 * Webfont: HelveticaNowDisplay-Bd by Monotype
 * URL: https://www.myfonts.com/fonts/mti/helvetica-now/display-bold/
 * 
 * Webfont: HelveticaNowDisplay-Md by Monotype
 * URL: https://www.myfonts.com/fonts/mti/helvetica-now/display-medium/
 * 
 * Webfont: HelveticaNowDisplay-XBd by Monotype
 * URL: https://www.myfonts.com/fonts/mti/helvetica-now/display-extrabold/
 * 
 * 
 * Licensed pageviews: 250,000
 * Webfonts copyright: Copyright Â© 2019 Monotype Imaging Inc. All rights reserved.
 * 
 * Â© 2019 MyFonts Inc
*/

/* @import must be at top of file, otherwise CSS will not work */

/* MyFonts Settings:
OpenType Layout: Selected
Hinting: Native
SVG format: Selected
Line Height: Bounding Box */


@font-face {
	font-family: 'Helvetica Now Display DW';
	font-weight: 400;
	font-style: normal;
	src: url(/static/media/3AAC62_0_0.6083e9de.woff2) format('woff2');
}

@font-face {
	font-family: "Helvetica Now Display DW";
	font-weight: 500;
	/* font-style: normal; */
	src: url(/static/media/3AAC62_2_0.b6d61193.woff2) format('woff2');
}

@font-face {
	font-family: "Helvetica Now Display DW";
	font-weight: 800;
	font-style: normal;
	src: url(/static/media/3AAC62_3_0.f4de430c.woff2) format('woff2');
}
/* Main browser scrollbar */
/* .main-scroll::-webkit-scrollbar {
    width: 17px;
    
}

.main-scroll::-webkit-scrollbar-track {
    border-radius: 10px;
    background-clip: content-box;
}

.main-scroll::-webkit-scrollbar-thumb {
    background-image: radial-gradient( rgb(34, 131, 196), rgb(27, 27, 27));
    border-radius: 10px; 
    cursor: grab;
}

.main-scroll::-webkit-scrollbar-track-piece {
    background-color: rgba(51, 51, 51, 1);
    cursor: grab;
} */


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body {
    font-family: 'Helvetica Now Display DW', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    background: rgb(255, 255, 255);

    
}

/* body { 
    margin-left: calc(100vw - 100%); 
    
    overflow-y: hidden;
} */


/*html { 
    /*for Mozilla
    scrollbar-color: rgb(34, 131, 196) rgb(27, 27, 27);
}*/

/* @media screen and (min-width: 960px) {
    html {
        margin-left: calc(100vw - 100%);
        margin-right: 0;
    }
} */

/* html {
    scroll-behavior: smooth;
} */

body {
    /* min-height: 100vh; */
    /* width: 100%; */
    font-size: 1.2rem;
    line-height: 1.75rem;
    font-weight: 100;
    /* letter-spacing: -0.005em; */
    letter-spacing: 0.25px;
    word-spacing: 5px;
    -webkit-text-size-adjust: 100%;
    color: rgba(10, 40, 61, 1);
    overflow-x: hidden;
    overflow-y: scroll; 
}

/* html {
    NOTE: this will NOT work in the same manner with React Router (Need to use other things (see bookmarks - React SCroll folder)
    scroll-behavior: smooth;
} */

.container {
    min-height: calc(100vh - 60px);
    /* height: 100%; */
    position: relative;
    width: 100vw;
    left: 50%;
    transform: translateX(-50%);
    /* padding: 0 2rem; */
    /* padding: 0 17px; */
    padding: 100px 5rem 2rem;
    /* padding: 0 2%; */
    /* side area with Iframes - so highly likely I need this here and then just just force a scroll to right on click of project view button! */
    overflow-x: hidden;
    /* chrome fix for scroll adding to overall body width */
    /* overflow-y: overlay; */
    /* overflow-y: scroll; */

    overflow-y: hidden;
    /*Avoid width reflow*/
    /* padding-right: 17px;  */
}

/*Links*/
/* .link {
    text-decoration: none;
    color: rgba(10, 40, 61, 1);
} */

a:active, 
a:focus,
button:active, 
button:focus {
    outline: 0;
    border: none;
    -moz-outline-style: none;
    outline-style: none;
}

/* .link:hover,
.link:active {
    text-decoration: underline;
} */

/* Headings */
.heading--bold {
    font-weight: 800;
}

.heading--normal {
    font-weight: 500;
}

.heading--light {
    font-weight: 400;
}

.heading--gradient {
    position: relative;
    left: 0;
    top: 0;
    background: -webkit-linear-gradient(rgb(34, 131, 196), rgb(27, 27, 27));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    /* padding: 20px 0 20px; */
}

/* H1's */
.heading--large {
    font-size: 70px;
    line-height: 74px;
    padding-top: 4px;
    padding-bottom: 1px;
    letter-spacing: -.01em;
}

/* H2's */
.heading--medium {
    font-size: 45px;
    line-height: 49px;
    padding-top: 4px;
    padding-bottom: 1px;
    letter-spacing: -.01em;
}

/* H3's */
.heading--small {
    font-size: 30px;
    line-height: 34px;
    padding-top: 4px;
    padding-bottom: 1px;
    letter-spacing: -.01em;
}

/*All other site-wide generic styles*/
.highlight {
    background: rgba(34, 131, 196, 0.85);
    color: rgba(255, 255, 255, 1) !important;
    border-radius: 3px;
    /* padding: 0 2.5px; */
    font-style: oblique;
    letter-spacing: 0.5px;
}

.back-to-top-btn {
    opacity: 0;
    display: inline;
    position: fixed;
    bottom: 3rem;
    right: 2rem;
    z-index: 99;
    border-radius: 50%;
    padding: 7.5px;
    width: 47px;
    height: 47px;
    background: rgba(143, 143, 143, 0.5);
    transition: opacity .65s cubic-bezier(.4,0,.5,.75), background .45s cubic-bezier(.3,0,.5,1) !important;
    cursor: pointer;
}

.back-to-top-btn:hover {
    background: rgba(10, 40, 61, 1);
    transition: background .45s cubic-bezier(.3,0,.5,1);
}

.back-to-top-btn--active {
    opacity: 1;
    transition: opacity .65s cubic-bezier(.4,0,.5,.75);
}

.back-to-top-btn__image {
    width: 35px;
    height: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media screen and (max-width: 768px) {
    body {
        font-size: 1.1rem;
        line-height: 1.65rem;
    }

    .container {
        padding: 100px 3rem 2rem;
    }

    .back-to-top-btn {
        right: 1.5rem;
    }
}

@media screen and (max-width: 425px) {
    body {
        font-size: 1rem;
        line-height: 1.55rem;
    }

    .container {
        padding: 100px 1rem 2rem;
    }

    .back-to-top-btn {
        right: 1rem;
    }
}
.nav-menu {
    position: fixed;
    visibility: hidden;
    width: 100vw;
    /* height: 100vh; */
    height: 0;
    /* top: 0; */
    /* top: calc(0 + 100vh); */
    /* top: -100vh; */
    left: 0;
    right: 0;
    /* bottom: calc(100% + 100vh); */
    /* bottom: 100%; */
    bottom: 0;
    /* padding: 100px 5rem 2rem; */
    /* top: calc(0 + 100vh); */

    background: rgba(72, 82, 72, 0.553); /*TEST COLOR - DELETE LATER*/
    background: rgba(5, 21, 32, 0.985);
    /* display: none; */
    
    /* transform: translateY(0); */
    /* transform: translate3d(0, 0, -500px); */
    /* transform: cubic-bezier(.17,.67,.83,.67); */
    transition: all 0.5s cubic-bezier(.3,0,.5,1);
    z-index: 999;
}

.nav-menu--active {
    visibility: visible;
}

.nav-menu__logo {
    width: 150px;
    position: absolute;
    /* left: calc(2% + 16px); */
    left: 2%;
    /* top: 7.745px; */
    top: 17.725px;
    opacity: 0;
    z-index: 1000;
}

.nav-menu__btn-close {
    /* display: block; */
    width: 60px;
    height: 85px;
    position: absolute;
    top: -1px;
    right: calc(2% + 16px);
    /* right: 2%; */
    background: transparent;
    border: none;
    cursor: pointer;
    font-weight: 700;
    font-size: 0.85rem;
    letter-spacing: 0.15em;
    text-align: center;
    z-index: 1000;
    /* opacity: 0.7; */
    transform: translate3d(0,0,0);
    transition: opacity .15s linear, font-size .25s ease-out;
    opacity: 0;
}

.nav-menu__btn-close:hover {
    /* opacity: 1; */
    transition: all .175s linear;
    transform: translate3d(0,0,0);
}

.nav-menu__btn-close:hover > .nav-menu__btn-close__text {
    color: #FFFFFF;
}

.nav-menu__btn-close:hover > .nav-menu__btn-close__line {
    background: #FFFFFF;
}

.nav-menu__btn-close:active,
.nav-menu__btn-close:focus { 
    border: none !important;
}

.nav-menu__btn-close__text {
    display: inline;
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    color: #7f8286;
    /* color: rgba(255, 255, 255, 1); */
}

.nav-menu__btn-close__line {
    position: relative;
    width: 28px;
    height: 3px;
    /* background: rgba(255, 255, 255, 1); */
    background: #7f8286;
    cursor: pointer;
    text-align: justify;
}

.nav-menu__btn-close__line:nth-of-type(1) {
    top: 13px;
    left: 16px;
    transform: rotate(45deg);
    transition: all .175s linear;
}

.nav-menu__btn-close__line:nth-of-type(2) {
    top: 10px;
    left: 16px;
    transform: rotate(-45deg);
    transition: all .175s linear;
}

.nav-menu__btn-close:hover > .nav-menu__btn-close__line:nth-of-type(1) {
    transform: rotate(135deg);
    transition: all .175s linear;
}

.nav-menu__btn-close:hover > .nav-menu__btn-close__line:nth-of-type(2) {
    transform: rotate(45deg);
    transition: all .175s linear;
}

.nav-menu__list {
    list-style: none;
    z-index: 1000;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.nav-menu__title {
    text-transform: uppercase;
    font-size: 0.975rem;
    font-weight: 100;
    letter-spacing: 5px;
    color: #7f8286;
    text-align: center;
    cursor: default;
}

.nav-menu__link {
    color: #7f8286;
    font-size: 3.75rem;
    line-height: 4.625rem;
    font-weight: 800;
    letter-spacing: 0.01em;
    text-decoration: none;
    text-transform: none;
    transition: .25s ease-in-out;
}

.nav-menu__link:hover {
    color: rgb(255, 255, 255);
    cursor: pointer;
    transition: .25s ease-in-out;
}

.nav-menu__link--current {
    position: relative;
    left: 0;
    top: 0;
    background: -webkit-linear-gradient(rgba(34, 131, 196, 1), rgba(27, 27, 27, 1));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
}

/*image underlays*/
/* .nav-menu__link:nth-child(1):hover {
    background-image: url('../../assets/img/contact-1.jpg');
    background-size: cover;
    background-repeat: no-repeat;
} */

/* @media screen and (max-width: 425px) {
    .nav-menu__logo {
        width: 170px;
        top: 12.195px;
        transition: width .25s ease-out;
    }

    .nav-menu__btn-close {
        font-size: 0.75rem;
        transition: font-size .25s ease-out;
    }
} */

@media screen and (max-height: 550px) {
    .nav-menu__link {
        font-size: 2.75rem;
        line-height: 3.125rem;
    }
}

@media screen and (max-height: 425px) {
    .nav-menu__list {
        top: 55%;
    }
}
.header {
    display: block;
    /* position: relative; */
    position: fixed;
    /* position: fixed; */ /*if using fixed it'll require more work on how nav menu comes up, making sure when close menu that previously scrolled to position stays etc... */
    
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100px;
    width: 100%;
    z-index: 99;
    background: transparent;
    transition: background 0.5s cubic-bezier(.3,0,.5,1);
}

.header__logo {
    position: absolute;
    left: 2%;
    top: 50%;
    transform: translateY(-50%);
    width: 150px;
    transition: width .25s ease-out;
}

.header__burger-menu-btn {
    /* display: block; */
    visibility: visible;
    width: 60px;
    height: 85px;
    position: absolute;
    top: 0;
    /* transform: translateY(-50%); */
    /* right: calc(2% - 8px); */
    right: 2%;
    background: transparent;
    border: none;
    cursor: pointer;
    font-weight: 700;
    font-size: 0.85rem;
    letter-spacing: 0.15em;
    text-align: center;
    z-index: 1000;
    opacity: 1;
    /* transition: all .15s linear; */
    transform: translate3d(0,0,0);
    /* transition: font-size .25s ease-out; */
    /* transition: opacity .3s linear, font-size .25s ease-out; */
}

/* .header__burger-menu-btn:hover {
    transition: all .15s linear;
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
} */

.header__burger-menu-btn:active, 
.header__burger-menu-btn:focus {
    border: none !important;
}

.header__burger-menu-btn__text {
    display: inline;
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    color: rgba(10, 40, 61, 1);
}

.header__burger-menu-btn__line {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 28px;
    height: 3px;
    background: rgba(10, 40, 61, 1);
    cursor: pointer;
    /* margin: 0 auto 5px; */
    text-align: justify;
}

.header__burger-menu-btn__line:nth-of-type(1) {
    top: 5px;
}

.header__burger-menu-btn__line:nth-of-type(2) {
    top: 10px;
}

.header__burger-menu-btn__line:nth-of-type(3) {
    top: 15px;
}

.header__burger-menu-btn:hover > .header__burger-menu-btn__line:nth-of-type(1) {
    top: 3px;
    width: 32px;
}

.header__burger-menu-btn:hover > .header__burger-menu-btn__line:nth-of-type(2) {
    left: 50%;
    transform: translateX(-50%);
}

.header__burger-menu-btn:hover > .header__burger-menu-btn__line:nth-of-type(3) {
    top: 17px;
    width: 32px;
}

@media screen and (max-width: 768px) {
    .header {
        background: rgba(255, 255, 255, 0.95);
        transition: background 0.5s cubic-bezier(.3,0,.5,1);
    }
}


/* @media screen and (max-width: 425px) {
    .header__logo {
        width: 170px;
        transition: width .25s ease-out;
    }

    .header__burger-menu-btn {
        font-size: 0.75rem;
        transition: font-size .25s ease-out;
    }
} */


/* .expand-height {
    min-height: 200vh;
} */

/* .text-bottom {
    position: absolute;
    top: 90%;
    top: 1560px;
} */

p:nth-child(odd) {
    color: #878a8f;
}



.footer {
    display: block;
    width: 100vw;
    height: 50px;
    position: relative;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    /* margin-top: 2rem; */
}

.footer__logo {
    display: block;
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    width: 44px;
    margin: 0 10px 0 0;
}

.footer__text {
    display: inline-block;
    position: absolute;
    left: 70px;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    color: #878a8f;
    font-weight: 400;
    font-size: .8125rem;
    line-height: 1.25rem;
    letter-spacing: .03em;
    word-spacing: .275em;
}

.footer__icon {
    width: 11px;
    margin: 0 10px;
}

.footer__title,
.footer__title-vertical {
    display: inline;
    opacity: 1;
    transition: opacity .5s ease-in-out;
    position: absolute;
    bottom: 0;
}

.footer__title {
    right: 60px;
    color: rgba(34, 131, 196, 0.95);
}

.footer__title-vertical {
    right: -70px;
    transform: rotate(-90deg) translateX(87px);
    color: rgba(10, 40, 61, 0.95);
}

@media screen and (max-width: 768px) {
    .footer__title,
    .footer__title-vertical {
        opacity: 0;
        transition: opacity .5s ease-in-out;
    }
}

@media screen and (max-width: 395px) {
    .footer {
        height: 70px;
    }
}

@media screen and (max-width: 320px) {
    .footer {
        height: 90px;
    }
}
h5.temp {
    font-size: 4rem;
    line-height: 6rem;
    text-align: center;
    text-decoration: underline;
}

h6.temp {
    font-size: 2rem;
    line-height: 4rem;
    letter-spacing: 1px;
    font-style: oblique;
    text-decoration: underline;
}

ol.temp {
    margin-bottom: 20px;
    padding: 0 2.5rem;
}

ol.temp li {
    font-weight: 700;
    text-transform: uppercase;
}

ol.temp li > ol {
    list-style-type:lower-roman;
    font-style: italic;
    margin-left: 20px;
}

ol.temp li > ol li {
    font-weight: 100;
    text-transform: none;
}

.header {
    display: block;
    /* position: relative; */
    position: fixed;
    /* position: fixed; */ /*if using fixed it'll require more work on how nav menu comes up, making sure when close menu that previously scrolled to position stays etc... */
    
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100px;
    width: 100%;
    z-index: 99;
    background: transparent;
    transition: background 0.5s cubic-bezier(.3,0,.5,1);
}

.header__logo {
    position: absolute;
    left: 2%;
    top: 50%;
    transform: translateY(-50%);
    width: 150px;
    transition: width .25s ease-out;
}

.header__burger-menu-btn {
    /* display: block; */
    visibility: visible;
    width: 60px;
    height: 85px;
    position: absolute;
    top: 0;
    /* transform: translateY(-50%); */
    /* right: calc(2% - 8px); */
    right: 2%;
    background: transparent;
    border: none;
    cursor: pointer;
    font-weight: 700;
    font-size: 0.85rem;
    letter-spacing: 0.15em;
    text-align: center;
    z-index: 1000;
    opacity: 1;
    /* transition: all .15s linear; */
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    /* transition: font-size .25s ease-out; */
    /* transition: opacity .3s linear, font-size .25s ease-out; */
}

/* .header__burger-menu-btn:hover {
    transition: all .15s linear;
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
} */

.header__burger-menu-btn:active, 
.header__burger-menu-btn:focus {
    border: none !important;
}

.header__burger-menu-btn__text {
    display: inline;
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    color: rgba(10, 40, 61, 1);
}

.header__burger-menu-btn__line {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 28px;
    height: 3px;
    background: rgba(10, 40, 61, 1);
    cursor: pointer;
    /* margin: 0 auto 5px; */
    text-align: justify;
}

.header__burger-menu-btn__line:nth-of-type(1) {
    top: 5px;
}

.header__burger-menu-btn__line:nth-of-type(2) {
    top: 10px;
}

.header__burger-menu-btn__line:nth-of-type(3) {
    top: 15px;
}

.header__burger-menu-btn:hover > .header__burger-menu-btn__line:nth-of-type(1) {
    top: 3px;
    width: 32px;
}

.header__burger-menu-btn:hover > .header__burger-menu-btn__line:nth-of-type(2) {
    left: 50%;
    transform: translateX(-50%);
}

.header__burger-menu-btn:hover > .header__burger-menu-btn__line:nth-of-type(3) {
    top: 17px;
    width: 32px;
}

@media screen and (max-width: 768px) {
    .header {
        background: rgba(255, 255, 255, 0.95);
        transition: background 0.5s cubic-bezier(.3,0,.5,1);
    }
}


/* @media screen and (max-width: 425px) {
    .header__logo {
        width: 170px;
        transition: width .25s ease-out;
    }

    .header__burger-menu-btn {
        font-size: 0.75rem;
        transition: font-size .25s ease-out;
    }
} */

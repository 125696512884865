/* .expand-height {
    min-height: 200vh;
} */

/* .text-bottom {
    position: absolute;
    top: 90%;
    top: 1560px;
} */

p:nth-child(odd) {
    color: #878a8f;
}
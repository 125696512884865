@import url('./assets/fonts/webfonts.css');

/* Main browser scrollbar */
/* .main-scroll::-webkit-scrollbar {
    width: 17px;
    
}

.main-scroll::-webkit-scrollbar-track {
    border-radius: 10px;
    background-clip: content-box;
}

.main-scroll::-webkit-scrollbar-thumb {
    background-image: radial-gradient( rgb(34, 131, 196), rgb(27, 27, 27));
    border-radius: 10px; 
    cursor: grab;
}

.main-scroll::-webkit-scrollbar-track-piece {
    background-color: rgba(51, 51, 51, 1);
    cursor: grab;
} */


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body {
    font-family: 'Helvetica Now Display DW', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    background: rgb(255, 255, 255);

    
}

/* body { 
    margin-left: calc(100vw - 100%); 
    
    overflow-y: hidden;
} */


/*html { 
    /*for Mozilla
    scrollbar-color: rgb(34, 131, 196) rgb(27, 27, 27);
}*/

/* @media screen and (min-width: 960px) {
    html {
        margin-left: calc(100vw - 100%);
        margin-right: 0;
    }
} */

/* html {
    scroll-behavior: smooth;
} */

body {
    /* min-height: 100vh; */
    /* width: 100%; */
    font-size: 1.2rem;
    line-height: 1.75rem;
    font-weight: 100;
    /* letter-spacing: -0.005em; */
    letter-spacing: 0.25px;
    word-spacing: 5px;
    -webkit-text-size-adjust: 100%;
    color: rgba(10, 40, 61, 1);
    overflow-x: hidden;
    overflow-y: scroll; 
}

/* html {
    NOTE: this will NOT work in the same manner with React Router (Need to use other things (see bookmarks - React SCroll folder)
    scroll-behavior: smooth;
} */

.container {
    min-height: calc(100vh - 60px);
    /* height: 100%; */
    position: relative;
    width: 100vw;
    left: 50%;
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    /* padding: 0 2rem; */
    /* padding: 0 17px; */
    padding: 100px 5rem 2rem;
    /* padding: 0 2%; */
    /* side area with Iframes - so highly likely I need this here and then just just force a scroll to right on click of project view button! */
    overflow-x: hidden;
    /* chrome fix for scroll adding to overall body width */
    /* overflow-y: overlay; */
    /* overflow-y: scroll; */

    overflow-y: hidden;
    /*Avoid width reflow*/
    /* padding-right: 17px;  */
}

/*Links*/
/* .link {
    text-decoration: none;
    color: rgba(10, 40, 61, 1);
} */

a:active, 
a:focus,
button:active, 
button:focus {
    outline: 0;
    border: none;
    -moz-outline-style: none;
    outline-style: none;
}

/* .link:hover,
.link:active {
    text-decoration: underline;
} */

/* Headings */
.heading--bold {
    font-weight: 800;
}

.heading--normal {
    font-weight: 500;
}

.heading--light {
    font-weight: 400;
}

.heading--gradient {
    position: relative;
    left: 0;
    top: 0;
    background: -webkit-linear-gradient(rgb(34, 131, 196), rgb(27, 27, 27));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    /* padding: 20px 0 20px; */
}

/* H1's */
.heading--large {
    font-size: 70px;
    line-height: 74px;
    padding-top: 4px;
    padding-bottom: 1px;
    letter-spacing: -.01em;
}

/* H2's */
.heading--medium {
    font-size: 45px;
    line-height: 49px;
    padding-top: 4px;
    padding-bottom: 1px;
    letter-spacing: -.01em;
}

/* H3's */
.heading--small {
    font-size: 30px;
    line-height: 34px;
    padding-top: 4px;
    padding-bottom: 1px;
    letter-spacing: -.01em;
}

/*All other site-wide generic styles*/
.highlight {
    background: rgba(34, 131, 196, 0.85);
    color: rgba(255, 255, 255, 1) !important;
    border-radius: 3px;
    /* padding: 0 2.5px; */
    font-style: oblique;
    letter-spacing: 0.5px;
}

.back-to-top-btn {
    opacity: 0;
    display: inline;
    position: fixed;
    bottom: 3rem;
    right: 2rem;
    z-index: 99;
    border-radius: 50%;
    padding: 7.5px;
    width: 47px;
    height: 47px;
    background: rgba(143, 143, 143, 0.5);
    transition: opacity .65s cubic-bezier(.4,0,.5,.75), background .45s cubic-bezier(.3,0,.5,1) !important;
    cursor: pointer;
}

.back-to-top-btn:hover {
    background: rgba(10, 40, 61, 1);
    transition: background .45s cubic-bezier(.3,0,.5,1);
}

.back-to-top-btn--active {
    opacity: 1;
    transition: opacity .65s cubic-bezier(.4,0,.5,.75);
}

.back-to-top-btn__image {
    width: 35px;
    height: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media screen and (max-width: 768px) {
    body {
        font-size: 1.1rem;
        line-height: 1.65rem;
    }

    .container {
        padding: 100px 3rem 2rem;
    }

    .back-to-top-btn {
        right: 1.5rem;
    }
}

@media screen and (max-width: 425px) {
    body {
        font-size: 1rem;
        line-height: 1.55rem;
    }

    .container {
        padding: 100px 1rem 2rem;
    }

    .back-to-top-btn {
        right: 1rem;
    }
}
h5.temp {
    font-size: 4rem;
    line-height: 6rem;
    text-align: center;
    text-decoration: underline;
}

h6.temp {
    font-size: 2rem;
    line-height: 4rem;
    letter-spacing: 1px;
    font-style: oblique;
    text-decoration: underline;
}

ol.temp {
    margin-bottom: 20px;
    padding: 0 2.5rem;
}

ol.temp li {
    font-weight: 700;
    text-transform: uppercase;
}

ol.temp li > ol {
    list-style-type:lower-roman;
    font-style: italic;
    margin-left: 20px;
}

ol.temp li > ol li {
    font-weight: 100;
    text-transform: none;
}